import input from '../uitls/inputCheck';
export default {
  data() {
    var checkEmail = (rule, value, callback) => {
      setTimeout(() => {
        const resEmail = input.checkEmail(value);
        if (resEmail !== -1) {
          callback(input.checkFormErrorMsg(resEmail));
        }
        callback();
      }, 300);
    };
    var checkPassword = (rule, value, callback) => {
      setTimeout(() => {
        const resPassword = input.checkPassword(value);
        if (resPassword !== -1) {
          callback(input.checkFormErrorMsg(resPassword));
        }
        callback();
      }, 300);
    };
    var checkPassword2 = (rule, value, callback) => {
      setTimeout(() => {
        const resPassword = input.checkPassword(value);
        if (resPassword !== -1) {
          callback(input.checkFormErrorMsg(resPassword));
        }
        if(value !== this.resetForm.password) {
          callback("两次密码需保持一致");
        }
        callback();
      }, 300);
    };
    var checkhasEmoji = (rule, value, callback) => {
      setTimeout(() => {
        const res = input.checkhasEmoji(value);
        const resSpace = input.checkIsSpace(value);
        if (res !== -1 || resSpace !== -1) {
          let error = (res !== -1) ? res : resSpace;
          callback(input.checkFormErrorMsg(error));
        }
        callback();
      }, 300);
    };
    return {
      form: {
        password: '',
        username: '',
        email: '',
        status: ''
      },
      resetForm: {
        password: '',
        passwordEncrypt: ''
      },
      pubKey:
        "-----BEGIN PUBLIC KEY-----\n" +
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDK969z3jGTsguG4jHQgT2Pnk+F\n" +
        "RCWqpYs9aisvkelztp2vQxIzjyzQecKYC1LUsqtAlVOH7qbS1ET2i5+RLNuBzRW4\n" +
        "iZcsYnLtpdr4cuBoGww9txwUDXbx9k7AgQCxSi3KEBvdYDktWT/sY3SNYHvlTw2N\n" +
        "9BtS4k1CNaEq8TAL2wIDAQAB\n" +
        "-----END PUBLIC KEY-----",
      rules:{
        username: [
            { required: true, message: '请输入账户名称', trigger: 'blur' },
            { min: 0, max: 32, message: '账户名称不超过32个字符', trigger: 'blur' },
            { validator: checkhasEmoji, trigger: 'blur' }
        ],
        email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { validator: checkEmail, trigger: 'blur' }
        ],
        password: [
            { required: true, message: '输入初始密码', trigger: 'blur' },
            { min: 6, max: 20, message: '密码长度为6-20位', trigger: 'blur' },
            { validator: checkPassword, trigger: 'blur' }
        ],
        role_id: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
      },
      previewRules: {
        email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { validator: checkEmail, trigger: 'blur' }
        ]
      },
      resetRules: {
        password: [
            { required: true, message: '输入初始密码', trigger: 'blur' },
            { min: 6, max: 20, message: '密码长度为6-20位', trigger: 'blur' },
            { validator: checkPassword, trigger: 'blur' }
        ],
        repassword: [
            { required: true, message: '输入确认密码', trigger: 'blur' },
            { min: 6, max: 20, message: '密码长度为6-20位', trigger: 'blur' },
            { validator: checkPassword2, trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    encryptPassword() {
      let jse = new this.$jsEncrypt();
      jse.setPublicKey(this.pubKey); // 加入rsa public key
      this.form.passwordEncrypt = jse.encrypt(this.form.password);
    },
    encryptResetPassword() {
      let jse = new this.$jsEncrypt();
      jse.setPublicKey(this.pubKey); // 加入rsa public key
      this.resetForm.passwordEncrypt = jse.encrypt(this.resetForm.password);
    },
    validForm(callback,form){
        this.$refs[form].validate((valid) => {
          if(valid){
            callback();
          };
        });
    }
  }
};