<template>
  <div class="user-management" v-if="hasResource('admin_user_management')">
    <div class="search-form">
      <el-button v-if="hasResource('admin_user_add')" type="primary" @click="addCustomer" class="black-btn">新增账户</el-button>
      <div class="dis-fix">
        <el-form :inline="true">
          <el-form-item label="账户名称">
            <el-input
              v-model="username"
              placeholder="输入账户名称"
              clearable/>
          </el-form-item>
          <el-form-item label="角色">
            <el-select v-model="role_id" placeholder="请选择">
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in roleList"
                :key="item.role_id"
                :label="item.role_name"
                :value="item.role_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="status" placeholder="请选择">
              <el-option label="全部" value></el-option>
              <el-option label="锁定" value="0"></el-option>
              <el-option label="正常" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="text-btn">
          <span class="serach-button" @click="handleQuery" v-loading="isQueryLoading">查询</span>
          <span class="clear-button"  @click="resetQuery">清空</span>
        </div>
      </div>
      
   </div>
      <el-table
        :data="userData"
        style="width: 100%"
        class="data-table"
        @selection-change="handleSelectionChange"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="isTableLoading"
        :row-class-name="tableRowClassName">
        <el-table-column prop="username" label="账户名称" align="left" :show-overflow-tooltip="true"  width="180"></el-table-column>
        <el-table-column prop="email" label="登陆邮箱" align="left" :show-overflow-tooltip="true"  width="240"></el-table-column>
        <el-table-column prop="role.role_name" label="角色" align="left"  width="120"></el-table-column>
        <el-table-column prop="status" label="状态" align="left" :show-overflow-tooltip="true" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">锁定</span>
            <span v-if="scope.row.status == 1">正常</span>
          </template>
        </el-table-column>
        <el-table-column align="left" class-name="small-padding fixed-width"  min-width="280" fixed="right">
          <template slot="header"> 
            <div style="padding-left:22px !important;" >操作</div>
          </template>
          <template slot-scope="scope">
            <div class="icon-btn">
              <el-button
                class="preview-color"
                size="mini"
                type="text"
                @click="updateCustomer(scope.row)"
                v-if="hasResource('admin_user_edit')"
              >
                <i class="iconfont ">&#xe677;</i>编辑
              </el-button>
              <el-button
                class="preview-color"
                size="mini"
                type="text"
                @click="handleResetPwd(scope.row)"
                v-if="hasResource('admin_user_reset_password')"
              >
                <i class="iconfont ">&#xe67b;</i>重置密码
              </el-button>
              <el-button
                class="preview-color"
                size="mini"
                type="text"
                @click="handleStop(scope.row.user_id)"
                v-if="hasResource('admin_user_delete')"
              >
                <i class="iconfont ">&#xe678;</i>删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="ta-r mg-t-18">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10,20,50]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>

      <!-- 新增弹框 -->
      <el-dialog top="12%"  :close-on-click-modal="false" title="新增账户" :visible.sync="dialogVisibleAdd" width="444px" class="pop-dialog" :modal-append-to-body='false'>
        <el-form ref="form" :rules="rules" :model="form" label-width="80px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="账户名称" prop="username">
                <el-input v-model="form.username" class="dialog-input" placeholder="输入账户名称" />
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="登录邮箱" prop="email">
                <el-input
                  v-model="form.email"
                  class="dialog-input"
                  placeholder="输入账户邮箱"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="账户密码" prop="password">
                <el-input
                  v-model="form.password"
                  class="dialog-input"
                  placeholder="输入用户密码"
                  show-password
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="关联角色" prop="role_id">
                <el-select v-model="form.role_id" placeholder="请选择">
                  <el-option
                    v-for="item in roleList"
                    :key="item.role_id"
                    :label="item.role_name"
                    :value="item.role_id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="账户状态">
                <el-radio v-model="form.status" :label="0">锁定</el-radio>
                <el-radio v-model="form.status" :label="1">正常</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="UserCreate" class="confirm-button" :loading="isDialogAddLoading">新增</el-button>
          <el-button @click="dialogVisibleAdd = false" class="cancel-button">取 消</el-button>
        </span>
      </el-dialog>

      <!-- 编辑弹框 -->
      <el-dialog top="12%"  title="编辑账户" :close-on-click-modal="false" :visible.sync="dialogVisibleUpdate" width="444px" class="pop-dialog" :modal-append-to-body='false'>
        <el-form ref="preview" :model="preview" :rules="previewRules" label-width="80px">
          <el-row>
            <el-col :span="24">
              <el-row class="el-form-item mg-l-10">
                <el-col :span="4" class="text-right"><div>账户名称</div></el-col>
                <el-col :span="20"><div class="pd-l-15">{{preview.username}}</div></el-col>
              </el-row>
            </el-col>
            <el-col :span="24">
              <el-form-item label="登录邮箱" :required="true" prop="email">
                <el-input v-model="preview.email" class="dialog-input" placeholder="输入账户邮箱" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="关联角色" prop="role_id">
                <el-select v-model="preview.role.role_id" placeholder="请选择">
                  <el-option
                    v-for="item in roleList"
                    :key="item.role_id"
                    :label="item.role_name"
                    :value="item.role_id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="账户状态">
                <el-radio v-model="preview.status" :label="0">锁定</el-radio>
                <el-radio v-model="preview.status" :label="1">正常</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="userUpdate" class="confirm-button" :loading="isDialogAddLoading">确定</el-button>
          <el-button @click="dialogVisibleUpdate = false" class="cancel-button">取 消</el-button>
        </span>
      </el-dialog>

      <!-- 重置密码弹框 -->
      <el-dialog top="12%"  :close-on-click-modal="false" title="" :visible.sync="dialogVisiblePassword" width="444px" class="pop-dialog reset-password" :modal-append-to-body='false'>
          <div class="text-center mg-10-auto pd-b-40">
            <div class="text-left">
                <p class="fz-32 fw-700">为{{password.username}}</p>
                <p class="pd-t-5 fz-18 fw-700 mg-t-10">设置一个新密码</p>
            </div>
            <div class="text-center mg-t-50">
              <el-form ref="resetForm" :model="resetForm" :rules="resetRules">
                <el-form-item label="" prop="password">
                  <el-input v-model="resetForm.password" class="dialog-input" placeholder="输入新密码" show-password/>
                </el-form-item>
                <el-form-item label="" prop="repassword">
                  <el-input v-model="resetForm.repassword" class="dialog-input" placeholder="确认新密码" show-password/>
                </el-form-item>
              </el-form>
            </div>
            <div class="mg-t-50">
              <el-button @click="UserUpdatePassword" class="ok-button" :loading="isDialogAddLoading">OK</el-button>        
            </div>
          </div>
      </el-dialog>

      <!-- 确认停用弹框 -->
      <el-dialog top="12%"  :close-on-click-modal="false" :visible.sync="dialogVisibleStop" width="458px"  class="pop-dialog" :modal-append-to-body='false'>
        <span class="fw-500 fz-18">是否确认删除该账户？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmStop" class="confirm-button">确 定</el-button>
          <el-button @click="dialogVisibleStop = false" class="cancel-button">取 消</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import api from "../../../api/user";
import roleApi from "../../../api/role";
import user from "../../../mixins/user";
export default {
  mixins: [user],
  data() {
    return {
      userData: [],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      username: "",
      status: "",
      role_id: "",
      roleList: {},
      // 新增弹框
      dialogVisibleAdd: false,
      form: {
        username: "",
        email: "",
        role_id: "",
        status: 1,
        password: "",
      },
      isQueryLoading: false,
      isTableLoading: false,
      isDialogAddLoading: false,
      dialogVisibleUpdate: false,
      dialogVisibleStop: false,
      dialogVisiblePassword: false,
      updateUserId: "",
      preview: {
        username: undefined,
        email: undefined,
        channel: undefined,
        status: 1,
        role: {
          role_id: undefined,
          role_name: undefined,
        },
      },
      stopRowId: 0,
      password: {
        username: "",
        user_id: "",
      },
      resetForm: {
        password: "",
        repassword: "",
        passwordEncrypt: "",
      },
      header: {
        url: "accountManagement",
      },
    };
  },
  methods: {
    init() {
      this.getUserList();
      this.getRoleList();
    },
    getRoleList() {
      let params = {
        limit: 99999,
        page: 1,
      };
      roleApi.adminRoleList(params).then((res) => {
        if (res.data.code === 10200) {
          this.roleList = res.data.data.items;
        }
      });
    },
    getUserList() {
      let params = {
        limit: this.pagesize,
        page: this.currentPage,
        filter: {
          username: this.username,
          status: this.status,
          role_id: this.role_id,
        },
      };
      this.isTableLoading = true;
      api.adminUserList(params).then((res) => {
        if (res.data.code === 10200) {
          this.userData = res.data.data.items;
          this.total = res.data.data.total;
          if((this.userData).length <= 0 && this.currentPage > 1){
            this.currentPage = 1;
            this.getUserList();
          }
        }
        if (res.data.code === 10100) {
          this.$router.push({ path: "/" });
        }
        this.isTableLoading = false;
        this.isQueryLoading = false;
      });
    },
    // 预览重置
    reset() {
      this.form = {
        status: 1,
      };
      this.$nextTick(function () {
        this.$refs["form"].clearValidate();
      });
    },
    resetUpdate() {
      this.preview = {
        username: undefined,
        email: undefined,
        channel: undefined,
        status: 1,
        role: {
          role_id: undefined,
          role_name: undefined,
        },
      };
    },
    resetPasswordForm() {
      this.resetForm = {
        password: "",
        repassword: "",
      };
      this.$nextTick(function () {
        this.$refs["resetForm"].clearValidate();
      });
    },
    // 搜索
    handleQuery() {
      this.isQueryLoading = true;
      this.currentPage = 1;
      this.getUserList();
    },
    // 重置
    resetQuery() {
      this.username = "";
      this.role_id = "";
      this.status = "";
      this.getUserList();
    },
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    //新增用户
    addCustomer() {
      this.dialogVisibleAdd = true;
      this.reset();
    },
    // 确认新增用户
    UserCreate() {
      this.validForm(this.userCreate, "form");
    },
    userCreate() {
      this.encryptPassword();
      let data = {
        username: this.form.username,
        email: this.form.email,
        role_id: this.form.role_id,
        password: this.form.passwordEncrypt,
        status: this.form.status,
      };
      this.isDialogAddLoading = true;
      api.createAdminUser(data).then((res) => {
        if (res.data.code === 10200) {
          this.$$success("成功");
          this.getUserList();
        } else {
          this.$$error(res.data.message);
        }
        this.isDialogAddLoading = false;
        this.dialogVisibleAdd = false;
      });
    },

    // 编辑账户
    updateCustomer(row) {
      this.resetUpdate();
      this.updateUserId = row.user_id;
      let data = row.user_id;
      this.isTableLoading = true;
      api.adminUserInfo(data).then((res) => {
        if (res.data.code === 10200) {
          this.dialogVisibleUpdate = true;
          this.preview = res.data.data;
        } else {
          this.$$error(res.data.message);
        }
        this.isTableLoading = false;
      });
    },
    userUpdate() {
      this.validForm(this.userUpdateConfirm, "preview");
    },
    // 确认修改
    userUpdateConfirm() {
      this.isDialogAddLoading = true;
      let data = {
        user_id: this.updateUserId,
        email: this.preview.email,
        role_id: this.preview.role.role_id,
        status: this.preview.status,
      };
      api.updateAdminUser(data).then((res) => {
        if (res.data.code === 10200) {
          this.$$success("成功");
          this.dialogVisibleUpdate = false;
          this.init();
        } else {
          this.$$error("成功");
        }
        this.isDialogAddLoading = false;
      });
    },
    // 删除用户
    deleteCustomer() {
      let customer_id = this.ids[0];

      api.customerDel(customer_id).then((res) => {

        if (res.data.code === 10200) {
          this.init();
        }
      });
    },
    // 表格中停用用户
    handleStop(row) {
      this.dialogVisibleStop = true;
      this.stopRowId = row;
    },
    //确认停用
    confirmStop() {
      let user_id = this.stopRowId;
      this.dialogVisibleStop = false;
      api.adminUserDelete(user_id).then((res) => {
        if (res.data.code === 10200) {
          this.getUserList();
          this.$$success("删除成功");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    handleStatusChange() {},
    handleResetPwd(row) {
      this.dialogVisiblePassword = true;
      this.password.user_id = row.user_id;
      this.password.username = row.username;
      this.resetPasswordForm();
    },
    UserUpdatePassword() {
      this.validForm(this.UserUpdateConfirmPassword, "resetForm");
    },
    UserUpdateConfirmPassword() {
      this.encryptResetPassword();
      let data = {
        password: this.resetForm.passwordEncrypt,
        user_id: this.password.user_id,
      };
      this.isDialogAddLoading = true;
      api.adminUserPwdEdit(data).then((res) => {
        if (res.data.code === 10200) {
          this.dialogVisiblePassword = false;
          this.$$success("成功");
        } else {
          this.$$error(res.data.message);
        }
        this.isDialogAddLoading = false;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserList();
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status === 0) {
        return "stop-row";
      }
      return "";
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less">
@import "scss/user-management.less";
</style>