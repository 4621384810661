import axios from '../uitls/require'

export default {
  // 角色列表
  adminRoleList(params) {
    return axios.post('/admin/role/list', params)
  },
  // 角色信息
  adminRoleInfo(role_id) {
    return axios.get('/admin/role?id=' + role_id)
  },
  // 角色创建
  createAdminRole(userInfo) {
    return axios.post('/admin/role/create', userInfo)
  },
  // 角色更新
  updateAdminRole(userInfo) {
    return axios.post('/admin/role/update', userInfo)
  },
  // 角色删除
  deleteAdminRole(user_id) {
    return axios.get('/admin/role/delete?id=' + user_id)
  },
  //角色权限
  getAdminRoleResource() {
    return axios.get('/admin/role/resource')
  }
}